import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Category } from 'src/app/shared/models/category/category.model';
import { SearchOptions } from 'src/app/shared/models/market-place/search-options.model';
import { ReferenceData } from 'src/app/shared/models/reference-data/reference-data.model';
import { AppStateService } from 'src/app/state/home/app-state.service';

interface ICategoryBarView {
  referenceData: ReferenceData | null,
  searchOptions: SearchOptions
}

@Component({
  selector: 'app-category-bar',
  templateUrl: './category-bar.component.html',
  styleUrls: ['./category-bar.component.scss']
})
export class CategoryBarComponent implements OnInit {

  referenceData$: Observable<ReferenceData | null> = this._appStateService.getReferenceData();
  searchOptions$: Observable<SearchOptions> = this._appStateService.searchOption$;
  categoryBarView$: Observable<ICategoryBarView> = this.getCategoryBarView();
  
  constructor(private _appStateService: AppStateService) { }

  ngOnInit(): void {
  }

  getCategoryBarView() {
    return combineLatest([this.referenceData$, this.searchOptions$]).pipe(
      map(([referenceData, searchOptions]) => ({
        referenceData,
        searchOptions
      }))
    );
  }

  selectCategory(category: Category, searchOptions: SearchOptions) {
    this._appStateService.selectCategory(category, searchOptions);
  }

}
