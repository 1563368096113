import { Injectable } from "@angular/core";
import { Query } from "@datorama/akita";
import { Observable } from "rxjs";
import { ReferenceData } from "src/app/shared/models/reference-data/reference-data.model";
import { SystemConfig } from "src/app/shared/models/system-config/system-config.model";
import { AppState, HomePageStore } from "./app-state.store";

@Injectable({
    providedIn: 'root'
})
export class AppStateQuery extends Query<AppState> {

    referenceData$: Observable<ReferenceData | null> = this.select('referenceData');
    systemConfig$: Observable<Array<SystemConfig>> = this.select('systemConfig');
    view$: Observable<number> = this.select('view');
    constructor(protected _store: HomePageStore) {
        super(_store);
    }

    updateReferenceData(referenceData: ReferenceData) {
        this._store.update( state => ({
            ...state,
            referenceData: referenceData
        }));
    }

    updateView(view: number) {
        this._store.update( state => ({
            ...state,
            view: view
        }))
    }

    updateSystemConfigs( systemConfigs: Array<SystemConfig>) {
        this._store.update( state => ({
            ...state,
            systemConfig: systemConfigs
        }))
    }
}