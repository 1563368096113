<div class="input-group search" *ngIf="searchBarView$ | async; let view">
    <div class="input-group-prepend">            
        <button class="btn btn-secondary dropdown-toggle" data-toggle="dropdown">
            <span>All</span><em class="icon mx-n1 ni ni-chevron-down"></em>
        </button>            
        <div class="dropdown-menu search__dd">
            <ul class="link-list-opt no-bdr" *ngIf="view.referenceData">
                <li *ngFor="let category of view.referenceData.categories"><a (click)="selectCategory(category, view.searchOptions)">{{ category.name }}</a></li>
            </ul>            
        </div>        
    </div>    
    <input type="text"
        class="form-control right rounded-right border-primary"
         placeholder="Search"
         (keyup.enter)="search(view.searchOptions)"
         [formControl]="searchCtrl"
         [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let search of predictiveSearch$ | async" [value]="search.searchTerm" (click)="selectTerm(search, view.searchOptions)">
            {{ search.searchTerm }}
        </mat-option>
    </mat-autocomplete>
</div>
