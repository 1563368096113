<div class="nk-app-root">
    <div class="nk-apps-sidebar is-theme" *ngIf="isUserLoggedIn$ | async; let  isLoggedIn">
        <app-primary-sidebar></app-primary-sidebar>
    </div>
    <!-- main @s -->
    <div class="nk-main">
        <!-- wrap @s -->
        <div class="nk-wrap" [class.sidebar-hide]="!(showSecondarySidebar$ | async)">
            <!-- main header @s -->
            <div class="nk-header nk-header-fixed is-light p-0">
                <app-header></app-header>
            </div>
            <!-- main header @e -->
            <div class="mt-5" style="margin-top: 50px;">
                <app-secondary-sidebar></app-secondary-sidebar>
            </div>
            
            <!-- content @s -->
            <div class="nk-content mt-3 pb-0">
                <router-outlet></router-outlet>
            </div>
            <!-- content @e -->
        </div>
        <!-- wrap @e -->
    </div>
    <!-- main @e -->
</div>

