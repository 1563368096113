import { SliderImage } from "src/app/home/components/home-slider/home-slider.component";
import { SystemConfig } from "../models/system-config/system-config.model";

export interface ISystemConfigView {
    slides: Array<SliderImage>;
    trendingCategories: Array<string>;
    topCategories: Array<string>;
    sliderFullWidth: boolean;
    showPopularProducts: boolean;
    showRecommendedProducts: boolean;
}

export const getSystemView = (systemConfigs: Array<SystemConfig>): ISystemConfigView => {
  let slides: Array<SliderImage> = [];
  let trendingCategories: Array<string> = [];
  let topCategories: Array<string> = [];
  let sliderFullWidth: boolean = false;
  let showPopularProducts: boolean = false;
  let showRecommendedProducts: boolean = false;
  systemConfigs.forEach( systemConfig => {
    switch(systemConfig.property) {
      case 'SLIDE':
        slides.push({
          url: systemConfig.config,
          id: systemConfig.id,
          description: systemConfig.meta
        })
        break;
      case 'TRENDING_CATEGORY':
        trendingCategories.push(systemConfig.config);
        break;
      case 'TOP_CATEGORIES':
        topCategories.push(systemConfig.config);
        break;
      case 'SLIDER_FULL_WIDTH':
        sliderFullWidth = systemConfig.config === 'YES' ? true : false;
        break;
      case 'SHOW_POPULAR_PRODUCTS':
        showPopularProducts = systemConfig.config === 'YES' ? true : false;
        break;
      case 'SHOW_RECOMMENDED_PRODUCTS':
        showRecommendedProducts = systemConfig.config === 'YES' ? true : false;
        break;
    }
  })
  return {
    slides,
    trendingCategories,
    topCategories,
    sliderFullWidth,
    showPopularProducts,
    showRecommendedProducts
  }
};
