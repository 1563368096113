import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AppWriteAuthService } from './shared/app-write/app-write.service';
import { ChatService } from './shared/app-write/chat.service';
import { DailyService } from './shared/services/api/daily.service';
import { FlashService } from './shared/services/flash/flash.service';
import { ReferenceDataService } from './shared/services/reference-data/reference-data.service';
import { SystemConfigService } from './shared/services/system-config/system-config.service';
import { AppStateQuery } from './state/home/app-state.query';
import { SessionQuery } from './state/session.query';
import { NotificationService } from './shared/services/notifications/notification.service';

declare global {
  interface Window {
    DailyIframe: any
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'supplyx-ui';
  unSubscribe$: Subject<void> = new Subject<void>();

  constructor (
    private _appWriteAuth: AppWriteAuthService,
    private _chatService: ChatService,
    public _flashService: FlashService,
    private _sessionQuery: SessionQuery,
    private _dailyService: DailyService,
    private _referenceDataService: ReferenceDataService,
    private _systemConfiguration: SystemConfigService,
    private _appStateQuery: AppStateQuery,
    private _notificationService: NotificationService
    ) { }

  ngOnInit(): void {
    this._sessionQuery.user$.pipe(
      tap( (user) => {
        if( user) {
          /* this._chatService.loadNotifications(user.id);
          this._chatService.listenToNotifications(user.id); */
          this._notificationService.connect(user.id);
        }
      }),
      takeUntil(this.unSubscribe$)
    ).subscribe();

    this._dailyService.openVideoCall$.pipe(
      tap( link => link ? this.openVideoCall(link) : ''),
      takeUntil(this.unSubscribe$)
    ).subscribe();

    combineLatest([
      this._referenceDataService.getReferenceData(), 
      this._systemConfiguration.getSystemConfigs()
    ]).pipe(
      tap(([referenceDataResponse, systemConfigResponse]) => {
        this._appStateQuery.updateReferenceData(referenceDataResponse.data);
        this._appStateQuery.updateSystemConfigs(systemConfigResponse.data);
      }),
      takeUntil(this.unSubscribe$)
    ).subscribe();   

    //interval(2000).pipe(tap(() => this._notificationService.sendMessage())).subscribe();

  }

  openVideoCall(url: string) {
    let callFrame = window.DailyIframe.createFrame({
      iframeStyle: {
        position: 'fixed',
        border: '1px solid black',
        width: '375px',
        height: '450px',
        right: '1em',
        bottom: '1em',
      },
      dailyConfig: {
        micAudioMode: 'music',
      },
      showLeaveButton: true,
      showFullscreenButton: true,
    });
    //callFrame.join({ url: 'https://supplyx.daily.co/getting-started' });
    callFrame.join({ url: url });
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }
}
