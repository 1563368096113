import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecondarySidebarComponent } from './secondary-sidebar.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [SecondarySidebarComponent],
  imports: [
    CommonModule,
    RouterModule,
    AutocompleteLibModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule
  ],
  exports: [
    SecondarySidebarComponent
  ]
})
export class SecondarySidebarModule { }
