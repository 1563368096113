import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../../models/auth/api-response.model';
import { DailyRoom } from '../../models/daily/daily.model';

@Injectable({
  providedIn: 'root'
})
export class DailyService {

  _openVideoCall$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  openVideoCall$: Observable<string | null> = this._openVideoCall$.asObservable();
  constructor(private _http: HttpClient) { }

  createRoom(): Observable<ApiResponse<DailyRoom>> {
    return this._http.post<ApiResponse<DailyRoom>>(`${environment.apiRoot}/${environment.daily}`, undefined)
  }

}
