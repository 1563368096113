import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { SessionQuery } from 'src/app/state/session.query';

@Component({
  selector: 'app-full-content-layout',
  templateUrl: './full-content-layout.component.html',
  styleUrls: ['./full-content-layout.component.scss']
})
export class FullContentLayoutComponent {

  showSecondarySidebar$: Observable<boolean> = this._sessionQuery.showSecondarySideBar$.pipe(debounceTime(300));
  isUserLoggedIn$: Observable<boolean> = this._sessionQuery.user$.pipe(
    map( user => !!user)
  );
  constructor(private _sessionQuery: SessionQuery) { }

}
