import { Routes } from '@angular/router';

export const Full_ROUTES: Routes = [
  // Common Modules
  { path: 'dashboard', loadChildren: () => import('../feature/dashboard/dashboard.module').then( m => m.DashboardModule) },
  { path: 'search', loadChildren: () => import('../search/search.module').then(m => m.SearchModule )},
  { path: 'home' , loadChildren: () => import('../home/home.module').then(m => m.HomeModule)},
  { path: 'profile', loadChildren: () => import('../feature/profile/profile.module').then( m => m.ProfileModule)},
  { path: 'chat', loadChildren: () => import('../chat/chat.module').then( m => m.ChatModule) },
  { path: 'cart', loadChildren: () => import('../feature/cart/cart.module').then(m => m.CartModule)},
  { path: 'activity', loadChildren: () => import('../my-activity/my-activity.module').then(m => m.MyActivityModule)},

  // ADMIN Modules
  { path: 'system-config', loadChildren: () => import('../system-config/system-config.module').then( m => m.SystemConfigModule)},
  { path: 'manage-users', loadChildren: () => import('../users/users.module').then( m => m.UsersModule) },

  // Buyer Modules
  { path: 'projects', loadChildren: () => import('../feature/projects/projects.module').then( m => m.ProjectsModule) },
  
  // Seller Modules
  { path: 'company', loadChildren: () => import('../company/company.module').then( m => m.CompanyModule)},
  { path: 'product', loadChildren: () => import('../feature/product/product.module').then( m => m.ProductModule) },

  // ORDER Modules
  { path: 'orders', children: [
      { path: 'buyer', loadChildren: () => import('../feature/order/buyer-orders/buyer-orders.module').then(m => m.BuyerOrdersModule)},
      { path: 'seller', loadChildren: () => import('../feature/order/seller-orders/seller-orders.module').then(m => m.SellerOrdersModule)}
    ]
  },

  // Added later
  { path: 'buyer-bid', loadChildren: () => import('../buyer/bid/bid.module').then(m => m.BidModule) },
  { path: 'take-off', loadChildren: () => import('../buyer/take-off/take-off.module').then( m => m.TakeOffModule)},
  { path: 'payment', loadChildren: () => import('../buyer/payments/payments.module').then( m => m.PaymentsModule)},

]
 