export const endPoints = {
  login: 'login',
  signup: 'signup',
  updateUser: 'users/update',
  changePassword: 'users/updatepassword',
  addProduct: 'products',
  payment: 'payment',
  getAllCartItems: 'cart/all',
  getProducts: 'products/all',
  addDraftProduct: 'products/addDraft',
  updateProductByStatus: 'products/status/:id',
  updateProductQuantity: 'products/quantity/:id',
  getProductById: 'products/:id',
  getAllProductsByKey: 'products/all',
  buldProductUpload: 'products/upload',
  company: 'company/:id',
  editCompany: 'company',
  editCartItems: '/update-cart',
  catalog: 'catalog',
  catalogById: 'catalog/:id',
  getAllCatalogs: 'catalog/all',
  getProductByCatalogId: 'catalogs/:id',
  upload: 'upload',
  getAllCategories: 'categories/all',
  addToCatalog: 'catalogs',
  getBrands: 'brands/all',

  addBuilding: 'buildings/add',
  addApartment: 'apartments/add',
  deleteApartment: '/apartments',
  takeoff: 'takeoff',
  video: 'video',
  image: 'image',
  takeoffinitiated: 'takeoffinitiated',
  deleteProductById: 'products',
  fetchOrdered: 'products/all/ordered',
  material: 'material', 
  fetchMaterial: 'fetchMaterial/:id',

  // apartment to order
  getOrderInApartment: 'apartment/materials/:apartmentId',
  addOrderInApartment: 'apartment/materials/add',

  //validationUrls
  //For UserRegister
  checkfirstname: 'ckeckfirstname/:fname',

  //myActivity
  myActivity: 'myactivity/all',

  // orders
  orderSaveAsMaterial: 'orders/saveasmaterial',

  referenceData: 'reference-data',
  daily: 'daily',
  getCart: 'cart',
  addCart: 'cart',
  deleteCart: 'cart/:id',

  //landing
  systemConfig: 'configs',
  deleteSystemConfig: 'configs/:id',

  //user
  userAll: 'users/all',
  updateUserByStatus: 'user/status/:id',
}
