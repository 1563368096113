import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserRole } from '../enums/user-role';
import { ChatService, Notification } from '../shared/app-write/chat.service';
import { User } from '../shared/models/auth/user.model';
import { Category } from '../shared/models/category/category.model';
import { SearchOptions } from '../shared/models/market-place/search-options.model';
import { ReferenceData } from '../shared/models/reference-data/reference-data.model';
import { AuthService } from '../shared/services/api/auth.service';
import { DailyService } from '../shared/services/api/daily.service';
import { AppStateService } from '../state/home/app-state.service';
import { SessionQuery } from '../state/session.query';

export interface Language {
  label: string;
  img: string;
  active: string;
}

export interface IHeaderView {
  user: User | null;
  roleType: string;
  languages: Array<Language>;
  activeLanguage: Language;
  referenceData: ReferenceData | null;
  searchOptions: SearchOptions;
  appMenuItems: Array<IAppMenuItem>
}

export interface IAppMenuItem {
  title: string;
  link: string;
  icon: string;
  bg: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  user$: Observable<User | null> = this._sessionQuery.user$;
  notifications$: Observable<Array<Notification>> = this._chatService.notifications$;
  referenceData$: Observable<ReferenceData | null> = this._appStateService.getReferenceData();
  searchOptions$: Observable<SearchOptions> = this._appStateService.searchOption$;
  languages: Language[] = [
    { label: "English", img: "assets/images/flags/english.png", active: "assets/images/flags/english-sq.png" },
    { label: "Español", img: "assets/images/flags/spanish.png", active: "assets/images/flags/spanish-sq.png"},
    { label: "Français", img: "assets/images/flags/french.png", active: "assets/images/flags/french-sq.png"},
    { label: "Türkçe", img: "assets/images/flags/turkey.png", active: "assets/images/flags/turkey-sq.png" }
  ];

  userRole = UserRole;
  appMenuItems: Array<IAppMenuItem> = [
    { title: 'Dashbaord', icon: 'ni-dashboard', bg: 'bg-warning-dim', link: '/home' },
    { title: 'Chats', icon: 'ni-chat-circle', bg: 'bg-info-dim', link: '/chats' },
    { title: 'Mailbox', icon: 'ni-inbox', bg: 'bg-purple-dim', link: '/mailbox' },
    { title: 'Messages', icon: 'ni-chat', bg: 'bg-success-dim', link: '/messages' },
  ];
  activeLanguage$: BehaviorSubject<Language> = new BehaviorSubject<Language>(this.languages[0]);
  headerView$: Observable<IHeaderView> = this.getHeaderView();

  constructor(
    private _authService: AuthService,
    private _sessionQuery: SessionQuery,
    private _router: Router,
    private _chatService: ChatService,
    private _dailyService: DailyService,
    private _appStateService: AppStateService,
    private _activatedRoute: ActivatedRoute) { }

  logout() {
    this._authService.logout();
    this._router.navigate(['/home'])
  }

  notificationAction(notification: Notification) {
    if(notification.type === 'MEETING') {
      this._dailyService._openVideoCall$.next(notification.link);
    }
  }

  getHeaderView(): Observable<IHeaderView> {
    return combineLatest([this.user$, this.activeLanguage$, this.referenceData$, this.searchOptions$]).pipe(
      map( ([user, activeLanguage, referenceData, searchOptions]) => ({
        user,
        roleType: user?.roleType || UserRole.GUEST,
        languages: this.languages,
        activeLanguage,
        referenceData,
        searchOptions,
        appMenuItems: this.appMenuItems
      }))
    )
  }

  selectCategory( category: Category, searchOptions: SearchOptions) {
    this._appStateService.selectCategory(category, searchOptions);
    this._router.navigate(['home','search'], {relativeTo: this._activatedRoute});
  }

}
