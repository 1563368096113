import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { FullContentLayoutComponent } from './layout/full-content-layout/full-content-layout.component';
import { CONTENT_ROUTES } from './routes/content-layout.routes';
import { Full_ROUTES } from './routes/full-layout.routes';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '', component: FullContentLayoutComponent, children: Full_ROUTES },
  { path: '', component: ContentLayoutComponent, children: CONTENT_ROUTES },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
