<ng-container *ngIf="secondarySidebarView$ | async; let sbv">
  <div class="nk-sidebar" data-content="sidebarMenu" *ngIf="sbv.showSecondarySideBar">
    <div class="nk-sidebar-inner mt-5">
      <ul class="nk-menu nk-menu-md">
        <li class="nk-menu-item" *ngFor="let menu of sbv.menus; index as i;" (click)="activateLink(i)" [class.active]="sbv.activeLinkIndex === i">
          <a class="nk-menu-link" [routerLink]="menu.url" *ngIf="!menu.disabled">
            <span class="nk-menu-text"  style="font-size: 15px;">{{ menu.title }}</span>
          </a>
          <a class="nk-menu-link" href="javascript:void(0)" *ngIf="menu.disabled">
            <span class="nk-menu-text">{{ menu.title }}</span>
          </a>
        </li><!-- .nk-menu-item -->
      </ul>
    </div>
  </div>
</ng-container>