
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserRole } from 'src/app/enums/user-role';
import { User } from 'src/app/shared/models/auth/user.model';
import { AuthService } from 'src/app/shared/services/api/auth.service';
import { SessionQuery } from 'src/app/state/session.query';

export interface MenuItem{
  title: string,
  icon: string,
  url: string,
  disabled?: boolean,
  fn?: () => void
}

export interface ISideBarView {
  user: User | null;
  menus: MenuItem[];
  activeMenu: number;
}

@Component({
  selector: 'app-primary-sidebar',
  templateUrl: './primary-sidebar.component.html',
  styleUrls: ['./primary-sidebar.component.scss']
})
export class PrimarySidebarComponent {

  activeMenu$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  sideBarView$: Observable<ISideBarView> = this.getSideBarView();
  userRole = UserRole;
  signOut = () => {
    this._authService.logout();
    this._router.navigate(['/home'])
  } 

  constructor(
    private _sessionQuery: SessionQuery, 
    private _authService: AuthService,
    private _router: Router) {  }

  takeOffMenus: MenuItem[] = [
    { title: 'Take Off', icon: 'navigate', url: 'take-off' },
    { title: 'Profile', icon: 'user-circle', url: 'profile' },
    { title: 'Sign out', icon: 'signout', url: '' },
  ]

  adminMenus: MenuItem[] = [
    { title: 'Manage Users', icon: 'users', url: 'manage-users' },
    { title: 'System Config', icon: 'opt', url: 'system-config' },
    { title: 'Sign out', icon: 'signout', url: '', fn: this.signOut },
  ]

  buyerMenus: MenuItem[] = [
    { title: 'Dashboard', icon: 'dashboard', url: 'dashboard' },
    { title: 'Projects', icon: 'building', url: 'projects' },
    { title: 'Market Place', icon: 'package', url: 'home'},
    { title: 'Orders', icon: 'bag', url: 'orders/buyer' },
    { title: 'Profile', icon: 'user-circle', url: 'profile' },
    { title: 'Sign out', icon: 'signout', url: '', fn: this.signOut },
  ]

  getSideBarView(): Observable<ISideBarView> {
    return combineLatest([this._sessionQuery.user$, this.activeMenu$]).pipe(
      map(([user, activeMenu]) => {
        let sideMenu: Array<MenuItem> = [];
        const roleType = user ? user.roleType : this.userRole.GUEST;
        switch(roleType) {
          case this.userRole.BUYER:
            sideMenu = this.buyerMenus;
            break;
          case this.userRole.SELLER:
            sideMenu = user ? this.getSellerMenu(user.company.id) : [];
            break;
          case this.userRole.TAKEOFF:
            sideMenu = this.takeOffMenus;
            break;
          case this.userRole.ADMIN:
            sideMenu = this.adminMenus;
            break;
          default:
            sideMenu = [];
        }
        return {
          user,
          menus: sideMenu,
          activeMenu: activeMenu
        }
      })
    );
  }

  getSellerMenu(companyId: number) {
    return [
      { title: 'Dashboard', icon: 'dashboard', url: 'dashboard'},
      { title: 'Products', icon: 'package-fill', url: 'product' },
      { title: 'Company', icon: 'building', url: `company/${companyId}` },
      { title: 'Order', icon: 'bag', url: 'orders/seller' },
      { title: 'Profile', icon: 'user-circle', url: 'profile' },
      { title: 'Sign out', icon: 'signout', url: '', fn: this.signOut },
    ]
  }

  activateLink(index: number) {
    this.activeMenu$.next(index);
  } 

}
