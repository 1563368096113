import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Category } from 'src/app/shared/models/category/category.model';
import { Hit } from 'src/app/shared/models/market-place/predictive-search.model';
import { SearchOptions } from 'src/app/shared/models/market-place/search-options.model';
import { ReferenceData } from 'src/app/shared/models/reference-data/reference-data.model';
import { SystemConfig } from 'src/app/shared/models/system-config/system-config.model';
import { ReferenceDataService } from 'src/app/shared/services/reference-data/reference-data.service';
import { SystemConfigService } from 'src/app/shared/services/system-config/system-config.service';
import { getSystemView, ISystemConfigView } from 'src/app/shared/utils/utils';
import { environment } from 'src/environments/environment';
import { AppStateQuery } from './app-state.query';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  _searchOption$: BehaviorSubject<SearchOptions> = new BehaviorSubject({
    page: 1,
    take: 20
  });
  searchOption$: Observable<SearchOptions> = this._searchOption$.asObservable();
  referenceData$: Observable<ReferenceData | null> = this.getReferenceData();
  systemConfigs$: Observable<SystemConfig[]> = this.getSystemConfigs();
  updateScore$: BehaviorSubject<Hit | null> = new BehaviorSubject<Hit | null>(null);
  
  constructor(
    private _query: AppStateQuery,
    private _referenceDataService: ReferenceDataService,
    private _systemConfiguration: SystemConfigService,
    private _router: Router,
    private _http: HttpClient
  ) { }

  getReferenceData(): Observable<ReferenceData | null> {
    return this._query.referenceData$
  }

  getSystemConfigs(): Observable<SystemConfig[]> {
    return this._query.systemConfig$;
  }

  getSystemConfigView(): Observable<ISystemConfigView> {
    return this.systemConfigs$.pipe(
      map( systemConfigs => getSystemView(systemConfigs))
    )
  }

  selectCategory( category: Category, searchOptions: SearchOptions) {
    this._searchOption$.next({
      ...searchOptions,
      category: category.name
    });
    this._router.navigate(['search'])
  }

  searchProduct( product: string, searchOptions: SearchOptions) {
    this._searchOption$.next({
      ...searchOptions,
      productName: product
    });
    this._router.navigate(['search'])
  }

  

}
