import { Component } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { ReferenceData } from 'src/app/shared/models/reference-data/reference-data.model';
import { SessionQuery } from 'src/app/state/session.query';
import { AutoCompleteItem } from 'src/app/shared/models/autocomplete/autocomplete.model';
import { AppStateService } from 'src/app/state/home/app-state.service';
import { UserRole } from 'src/app/enums/user-role';
import { User } from 'src/app/shared/models/auth/user.model';

export interface MenuItem {
  title: string,
  url: string,
  disabled?: boolean
}

export interface SearchModel {
  name: string,
}

export interface ISecondarySidebarView {
  menus: Array<MenuItem>;
  activeLinkIndex: number;
  showSecondarySideBar: boolean;
  referenceData: ReferenceData | null;
  brandAutoCompleteData: Array<AutoCompleteItem>;
  userRole: UserRole;
}

@Component({
  selector: 'app-secondary-sidebar',
  templateUrl: './secondary-sidebar.component.html',
  styleUrls: ['./secondary-sidebar.component.scss']
})
export class SecondarySidebarComponent {

  activeLinkIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  showSecondarySideBar$: Observable<boolean> = this._sessionQuery.showSecondarySideBar$;
  activeMenus$: Observable<Array<MenuItem>> = this.getActiveMenuList();
  referenceData$: Observable<ReferenceData | null> = this._appStateService.referenceData$;
  secondarySidebarView$: Observable<ISecondarySidebarView> = this.getSidebarView();

  productMenu: MenuItem[] = [
    { title: 'Your Product', url: 'product' },
   /*  { title: 'Add Product', url: 'product/add' }, */
    { title: 'Product Catalog', url: 'product/catalog' }
  ]

  companyMenus: MenuItem[] = [
    { title: 'Company Profile', url: '/about-us' },
    { title: 'Why Choose Us', url: '/why-choose-us' },
    { title: 'Gallery', url: '/gallery' },
    { title: 'Contact Us', url: '/contact-us' }
  ]

  dashboardMenu: MenuItem[] = [
    { title: 'Dashboards', url: 'buyer-dashboard' },
    { title: 'Projects', url: 'projects' },
    { title: 'Market Place', url: 'market-place' },
    { title: 'Cart', url: 'cart' },
    { title: 'My Orders', url: 'projects/add-project/add' },
    { title: 'Profile', url: 'projects/add-project/add' },
  ]
  
  orderdMenu: MenuItem[] = [
    { title: 'For Confirmation', url: 'orders'},
    { title: 'Ready For payment', url: 'orders/readyforpayment'},
    { title: 'Completed Orders', url: 'orders/completedorders'},
    /* { title: 'My Orders', url: 'orders' },
    { title: 'Submit Order', url: 'orders/submit-order' },
    { title: 'Shipping Info', url: 'shipping-information' },
    { title: 'Payment', url: 'orders/for-payment/payment' },
    { title: 'Thank You', url: 'orders/thankyou-submitted' },
    { title: 'For Confirmation', url: 'orders/for-confirmation' },
    { title: 'For Payments', url: 'orders/for-payment' },
    { title: 'Preparing Shipment', url: 'orders/preparing-shipment' },
    { title: 'Order in Transit', url: 'orders/order-in-transit' },
    { title: 'Completed', url: 'orders' }, */
  ]

  constructor(
    private _routerQuery: RouterQuery, 
    private _appStateService: AppStateService,
    private _sessionQuery: SessionQuery) { }

  getActiveMenuList() {
    return combineLatest([this._routerQuery.select('state'), this._sessionQuery.user$]).pipe(
      switchMap(([state, user]) => state?.url ? of(this.getActiveMenu(state.url, user)) : of([]))
    )
  }

  getSidebarView(): Observable<ISecondarySidebarView> {
    return combineLatest([
      this.activeMenus$, 
      this.activeLinkIndex$, 
      this.showSecondarySideBar$, 
      this.referenceData$,
      this._sessionQuery.user$]).pipe(
      map(([menus, activeLinkIndex, showSecondarySideBar, referenceData, user]) => ({
        menus,
        activeLinkIndex,
        showSecondarySideBar,
        referenceData,
        brandAutoCompleteData: referenceData?.brands.map( brand => ({
          name: brand
        })) || [],
        userRole: user?.roleType || UserRole.GUEST
      }))
    )
  }

  getActiveMenu(url: string,  user: User | null): Array<MenuItem> {
    if (['dashboard', 'profile', 'cart', 'projects', 'search', 'company', 'chat'].filter( menu => url.includes(menu)).length) {
      this._sessionQuery.updateShowSecondaryBar(false);
      return this.dashboardMenu;
    }
    else if(url.includes('home')) {
      this._sessionQuery.updateShowSecondaryBar(false);
      return [];
    }
    else if(url.includes('manage-users')) {
      this._sessionQuery.updateShowSecondaryBar(false);
      return this.dashboardMenu;
    } else if(url.includes('product') && user && user.roleType === UserRole.SELLER )  {
      this._sessionQuery.updateShowSecondaryBar(true);
      return this.productMenu;
    }/*  else if(url.includes('order')) {
      this._sessionQuery.updateShowSecondaryBar(true);
      return this.orderdMenu;
    } */
    else {
      this._sessionQuery.updateShowSecondaryBar(false);
      return [];
    }
  }

  activateLink(index: number) {
    this.activeLinkIndex$.next(index);
  }

}
