import { Injectable } from "@angular/core";
import { Query } from "@datorama/akita";
import { Observable } from "rxjs";
import { UserRole } from "../enums/user-role";
import { User } from "../shared/models/auth/user.model";
import { Catalog } from "../shared/models/catalog/catalog.model";
import { SessionState, SessionStore } from "./session.state";

@Injectable({
    providedIn: 'root'
})
export class SessionQuery extends Query<SessionState> {

    user$: Observable<User | null> = this.select('user');
    role$: Observable<string | null> = this.select('role');
    editCatalog$: Observable<Catalog> = this.select('editCatalog');
    showSecondarySideBar$: Observable<boolean> = this.select('showSecondarySideBar');

    constructor(protected _store: SessionStore) {
        super(_store);
    }

    updateShowSecondaryBar( show: boolean) {
        this._store.update( state => ({
            state,
            showSecondarySideBar: show
        }))
    }

    updateUserDetails( user: User | null) {
        this._store.update( state => ({
          ...state,
          user: user,
          role: user?.roleType || UserRole.GUEST,
          isLoggedIn: true
        }));
    }

}