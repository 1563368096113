import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { RouterModule } from '@angular/router';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { CategoryBarComponent } from './category-bar/category-bar.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgxTypeaheadModule } from 'ngx-typeahead';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { SecondryHeaderComponent } from './secondry-header/secondry-header.component';

const COMPS = [
  HeaderComponent,
  SearchBarComponent
]

@NgModule({
  declarations: [
    ...COMPS,
    CategoryBarComponent,
    SecondryHeaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    AutocompleteLibModule,
    NgxTypeaheadModule,
    MatAutocompleteModule,
    ReactiveFormsModule
  ],
  exports: [
    ...COMPS
  ]
})
export class HeaderModule { }
