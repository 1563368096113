import { Injectable } from "@angular/core";
import { PaginationResponse, Store, StoreConfig } from "@datorama/akita";
import { AutoCompleteItem } from "src/app/shared/models/autocomplete/autocomplete.model";
import { SearchOptions } from "src/app/shared/models/market-place/search-options.model";
import { Product } from "src/app/shared/models/product/product.model";
import { ReferenceData } from "src/app/shared/models/reference-data/reference-data.model";
import { SystemConfig } from "src/app/shared/models/system-config/system-config.model";

export interface AppState {
    referenceData: ReferenceData | null;
    autoCompleteData: Array<AutoCompleteItem>;
    searchOptions: SearchOptions | null;
    pagination: PaginationResponse<Product>;
    systemConfig: Array<SystemConfig>;
    view: number;
}

export const initialHomePageState = (): AppState => ({
    referenceData: null,
    searchOptions: null,
    autoCompleteData: [],
    pagination: {
        currentPage: 1,
        lastPage: 1,
        perPage: 10,
        data: []
    },
    systemConfig: [],
    view: 0
})

@Injectable({
    providedIn: 'root'
})
@StoreConfig({ name: 'home-page-store' })
export class HomePageStore extends Store<AppState> {
    constructor() {
        super(initialHomePageState());
    }
}