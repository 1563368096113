<div class="container-fluid bg-dark" *ngIf="categoryBarView$ | async; let view">
    <div class="container" *ngIf="view.referenceData">
        <div class="dropdown">
            <a href="#" class="btn btn-dark p-2" data-toggle="dropdown">
                <span>Show All</span>
                <em class="icon ni ni-chevron-down"></em>
            </a>
            <div class="dropdown-menu dropdown-menu-end dropdown-menu-auto mt-1 category__dd">
                <ul class="link-list-plain">
                    <li *ngFor="let category of view.referenceData.categories; index as i">
                        <a (click)="selectCategory(category, view.searchOptions)">{{ category.name }}</a>
                    </li>
                </ul>
            </div>
        </div>
        <button 
            *ngFor="let category of view.referenceData.categories | slice:0:8 index as i"
            type="button"
            class="btn btn-dark" 
            (click)="selectCategory(category, view.searchOptions)">
            {{ category.name }}
        </button>
<!--         <div class="dropdown">
            <a href="#" class="btn btn-dark p-2" data-toggle="dropdown">
                <em class="icon ni ni-more-h"></em>
            </a>
            <div class="dropdown-menu dropdown-menu-end dropdown-menu-auto mt-1 category__dd">
                <ul class="link-list-plain" *ngIf="view.referenceData">
                    <li *ngFor="let category of view.referenceData.categories | slice:9; index as i">
                        <a (click)="selectCategory(category, view.searchOptions)">{{ category.name }}</a>
                    </li>
                </ul>
            </div>
        </div> -->
    </div>
</div>