import { InjectionToken } from '@angular/core';
import { environment } from 'src/environments/environment';

interface NotificationConfig {
  wsEndPoint: string;
}

export const NotificationConfig = new InjectionToken<NotificationConfig>(
  'Notification Config',
  {
    providedIn: 'root',
    factory() {
      const { apiRoot } = environment;
      const protocol = apiRoot.includes('https:') ? 'wss://' : 'ws://';
      const wsEndPoint = protocol.concat(apiRoot.split("://").pop() || '')
      return {
        wsEndPoint
      };
    },
  }
);