<div class="container-fluid bg-indigo" *ngIf="secondryHeaderView$ | async; let view">
    <div class="row">
        <div class="col-sm-6">
            <div class="d-flex justify-content-center align-items-center text-light">
                <div class="ml-4 content"><a>About Us</a></div>
                <div class="ml-4 content"><a>Blog</a></div>
                <div class="ml-4 content"><a>Need Help?</a></div>
                <div class="ml-4 content">
                    <div class="btn-group">
                        <div class="btn-group">
                            <button type="button" class="btn content text-light fw-normal dropdown-toggle dropdown-toggle-split" data-toggle="dropdown">
                                <span>Lang:</span>
                                <span class="ml-1" style="display: block;">{{view.activeLanguage.label}}</span>
                                <em class="icon ni ni-chevron-down ml-1"></em>
                            </button>
                            <div class="dropdown-menu">
                                <ul class="language-list">
                                    <li *ngFor="let  language of  languages">
                                        <a (click)="changeLanguage(language)" class="language-item">
                                        <span class="language-name">{{  language.label }}</span></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ml-4 content">
                    <div class="btn-group">
                        <button type="button" class="btn content text-light fw-normal dropdown-toggle dropdown-toggle-split" data-toggle="dropdown">
                            Follow Us<em class="ml-1 icon ni ni-chevron-down"></em>
                        </button>
                        <div class="dropdown-menu">
                            <ul class="link-list-opt no-bdr">
                                <li><a href="#"><span>FaceBook</span></a></li>
                                <li><a href="#"><span>Instagram</span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="ml-4 content ">
                    <div class="d-flex my-1 align-items-center">
                        <em class="mt-1 icon ni ni-shield-check-fill h5 text-light" style="display: block;"></em>
                        <p class="ml-1" style="display: block;"> 100% Secure shipping & delivery</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-6" style="padding-top: 1px;">
            <div class="d-flex justify-content-center align-items-center text-light">
                <div class="ml-4 content"><a class="text-light" routerLink="/register">Become A Seller</a></div>
                <div class="ml-4 content"><a>Shipping & Delivery</a></div>
                <div class="ml-4 content"><a>Order Tracking</a></div>
                <div class="ml-4 content">
                    <div class="btn-group">
                        <button type="button" class="btn content text-light fw-normal dropdown-toggle dropdown-toggle-split" data-toggle="dropdown">
                            <!-- <span>$</span> --> 
                            <span class="ml-1">{{view.activeCurrency}}</span>
                            <em class="icon ni ni-chevron-down"></em>
                        </button>
                        <div class="dropdown-menu">
                            <ul class="language-list">
                                <li *ngFor="let currency of currencies">
                                    <a (click)="changeCurrency(currency)" class="language-item">
                                    <span class="language-name" style="display: block;">{{ currency }}</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="ml-4 content">
                    <div class="btn-group">
                        <button type="button" class="btn content text-light fw-normal dropdown-toggle dropdown-toggle-split" data-toggle="dropdown">
                            <span>Ship To</span><em class="icon ni ni-chevron-down"></em>
                        </button>
                        <div class="my-2 d-flex align-items-center">
                            <img class="icon" width="20" height="15" 
                            [src]="[view.activeCountry.active]" style="display: block;">
                            <span class="mx-2" style="display: block;">{{view.activeCountry.label}}</span>
                        </div>
                        <div class="dropdown-menu">
                            <ul class="language-list">
                                <li *ngFor="let country of countries">
                                    <a (click)="changeCountry(country)" class="language-item">
                                    <img [src]="country.img" alt="" class="language-flag">
                                    <span class="language-name">{{ country.label }}</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
