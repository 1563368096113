import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiResponse } from '../../models/auth/api-response.model';
import { environment } from 'src/environments/environment';
import { SystemConfig } from '../../models/system-config/system-config.model';

@Injectable({
  providedIn: 'root'
})
export class SystemConfigService {

  constructor(private _http: HttpClient) { }
  systemSettingReferesh$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  
  getSystemConfigs(): Observable<ApiResponse<Array<SystemConfig>>> {
    return this._http.get<ApiResponse<Array<SystemConfig>>>(`${environment.apiRoot}/${environment.systemConfig}`)
  }

  updateSystemConfigs(configs: Array<SystemConfig>): Observable<ApiResponse<Array<SystemConfig>>> {
    return this._http.post<ApiResponse<Array<SystemConfig>>>(`${environment.apiRoot}/${environment.systemConfig}`, {
      configs: configs
    })
  }

  delete(id: number) {
    return this._http.delete(`${environment.apiRoot}/${environment.deleteSystemConfig.replace(':id', id.toString())}`)
  }

}
