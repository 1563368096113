import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Language {
  label: string;
  img: string;
  active: string;
}

export interface Country {
  label: string;
  img: string;
  active: string;
}

export interface IHeaderView {
  languages: Array<Language>;
  activeLanguage: Language;
  countries: Array<Country>;
  activeCountry: Country;
  currencies: Array<string>;
  activeCurrency: string;
}

@Component({
  selector: 'app-secondry-header',
  templateUrl: './secondry-header.component.html',
  styleUrls: ['./secondry-header.component.scss']
})
export class SecondryHeaderComponent implements OnInit {

  currencies: string[] = [
    'USD', 'EUR', 'GBP', 'AUD', 'RUB'

  ]

  languages: Language[] = [
    { label: "EN", img: "assets/images/flags/english.png", active: "assets/images/flags/english-sq.png" },
    { label: "ES", img: "assets/images/flags/spanish.png", active: "assets/images/flags/spanish-sq.png"},
    { label: "FR", img: "assets/images/flags/french.png", active: "assets/images/flags/french-sq.png"},
    { label: "TR", img: "assets/images/flags/turkey.png", active: "assets/images/flags/turkey-sq.png" }
  ];

  countries: Country[] = [
    { label: "USA", img: "assets/images/flags/english.png", active: "assets/images/flags/english-sq.png" },
    { label: "ES", img: "assets/images/flags/spanish.png", active: "assets/images/flags/spanish-sq.png"},
    { label: "FR", img: "assets/images/flags/french.png", active: "assets/images/flags/french-sq.png"},
    { label: "TR", img: "assets/images/flags/turkey.png", active: "assets/images/flags/turkey-sq.png" }
  ];
  activeLanguage$: BehaviorSubject<Language> = new BehaviorSubject<Language>(this.languages[0]);
  activeCountry$: BehaviorSubject<Country> = new BehaviorSubject<Country>(this.countries[0]);
  activeCurrency$: BehaviorSubject<string> = new BehaviorSubject<string>(this.currencies[0]);
  secondryHeaderView$: Observable<IHeaderView> = this.getView();
  
  constructor() { }
  

  ngOnInit(): void {
  }

  changeLanguage(lang: Language) {
    this.activeLanguage$.next(lang);
  }

  changeCountry(country: Country) {
    this.activeCountry$.next(country);
  }

changeCurrency(currency: string) {
  this.activeCurrency$.next(currency);
}

  getView(): Observable<IHeaderView> {
    return combineLatest([this.activeLanguage$, this.activeCountry$, this.activeCurrency$ ]).pipe(
      map( ([activeLanguage, activeCountry, activeCurrency]) => ({
        languages: this.languages,
        activeLanguage,
        countries: this.countries,
        activeCountry,
        currencies: this.currencies,
        activeCurrency
      }))
    )
  }

}
