<div class="footer">
    <div class="footer-1 py-2">
        <div class="content">
            <p class="title-1 mb-1">Subscribe our newsletter </p>
            <p class="subtitle-1 mb-1">Be updated with our latest product, supplies and industry new</p>
            <div class="form-control-wrap">
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Your email address">
                    <div class="input-group-append">
                        <button class="btn btn-primary">Subscribe</button>
                    </div>
                </div>
            </div>
            <p class="subtitle-2">Your email will never be shared to others.</p>
        </div>
    </div>
    <div class="footer-2">
        <div class="content">
            <div class="row">
                <div class="col">
                    <p class="title-1">Customer Care</p>
                    <p class="subtitle-1 mb-1">Help Center</p>
                    <p class="subtitle-1 mb-1">Report Abuse</p>
                    <p class="subtitle-1 mb-1">Payments</p>
                    <p class="subtitle-1 mb-1">Shipping & Delivery</p>
                </div>
                <div class="col">
                    <p class="title-1">About Us</p>
                    <p class="subtitle-1 mb-1">About SupplyX</p>
                    <p class="subtitle-1 mb-1">Sitemap</p>
                    <p class="subtitle-1 mb-1">Blog</p>
                    <p class="subtitle-1 mb-1">Legal Notice</p>
                </div>
                <div class="col">
                    <p class="title-1">Shop on SupplyX</p>
                    <p class="subtitle-1 mb-1">All categories</p>
                    <p class="subtitle-1 mb-1">Request for Quotations</p>
                    <p class="subtitle-1 mb-1">Ready to Ship</p>
                    <p class="subtitle-1 mb-1">Contact Manuacturers</p>
                </div>
                <div class="col">
                    <p class="title-1">Be a Supplier</p>
                    <p class="subtitle-1 mb-1">Become a Seller</p>
                    <p class="subtitle-1 mb-1">About our Partnership</p>
                </div>
                <div class="col">
                    <p class="title-1">Get Started</p>
                    <p class="subtitle-1 mb-1">Login/Signup</p>
                    <p class="subtitle-1 mb-1">Our Platform</p>
                </div>
                <div class="col">
                    <p class="title-1">Follow Us</p>
                    <div class="subtitle-1 mb-1 d-flex align-items-center">
                        <em class="icon ni ni-facebook-circle h6 text-white mb-0"></em>
                        <span class="ml-1">Facebook</span>
                    </div>
                    <div class="subtitle-1 mb-1 d-flex align-items-center">
                        <em class="icon ni ni-twitter-round h6 text-white mb-0"></em>
                        <span class="ml-1">Twitter</span>
                    </div>
                    <div class="subtitle-1 mb-1 d-flex align-items-center">
                        <em class="icon ni ni-linkedin-round h6 text-white mb-0"></em>
                        <span class="ml-1">Linkedin</span>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-6">
                    <div class="d-flex align-items-center">
                        <img src="../../assets/icons/SupplyXLogoHD.svg" alt="">
                        <div class="text-muted logo-text">SUPPLYX</div>
                    </div>
                </div>
               <!--  <div class="col">
                    <div class="form-group">
                        <label class="subtitle-2">Currency</label>
                        <div class="">
                            <select class="">
                                <option value="">1</option>
                                <option value="">2</option>
                            </select>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="col">
                    <div class="form-group">
                        <label class="subtitle-2">Language</label>
                        <div class="">
                            <select class="">
                                <option value="">English</option>
                                <option value="">French</option>
                            </select>
                        </div>
                    </div>
                 </div> -->
                <!--  <div class="col">
                    <div class="form-group">
                        <label class="subtitle-2">Ship To</label>
                        <div class="">
                            <select class="">
                                <option value="">USA</option>
                                <option value="">CHINA</option>
                            </select>
                        </div>
                    </div>
                 </div> -->
            </div>
            <hr>
            <div class="d-flex justify-content-between">
                <div>
                    <p class="policy">All rights reserved 2023SupplyX.com</p>
                </div>
                <div class="d-flex">
                    <div>
                        <p class="policy">Product Listing</p>
                    </div>
                    <div class="ml-2">
                        <p class="policy">Intellectual Property Protection</p>
                    </div>
                    <div  class="ml-2">
                        <p class="policy ">Privacy Policy</p>
                    </div>
                    <div  class="ml-2">
                        <p class="policy">Terms of Use</p>
                    </div>
                    <div  class="ml-2">
                        <p class="policy">User Information Legal Enquiry Guide</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


