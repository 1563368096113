<div class="nk-apps-brand p-3">
    <!-- <a *ngFor="let menu of uri" [routerLink]="[menu.url]">
        <img src="../../../assets/icons/SupplyXlogo.svg" height="50" width="60" alt="">
    </a> -->
</div>
<div class="nk-sidebar-element" *ngIf="sideBarView$ | async; let sv">
    <div class="nk-sidebar-body">
        <div class="nk-sidebar-content" data-simplebar>
            <div class="nk-sidebar-menu">
                <!-- Menu -->
                <ul class="nk-menu apps-menu">
                    <li class="nk-menu-item"  *ngFor="let menu of sv.menus; index as i;" (click)="activateLink(i)" [class.active]="sv.activeMenu === i">
                        <ng-container *ngIf="!menu.fn">
                            <a *ngIf="!menu.disabled; else disabledMenu"
                                placement="right" 
                                container="body" 
                                [ngbTooltip]="menu.title" 
                                class="nk-menu-link" 
                                [routerLink]="[menu.url]" 
                                >
                                <div class="d-flex flex-column nk-menu-icon">
                                    <em class="icon ni ni-{{menu.icon}} mb-1"></em>
                                    <p class="fw-light text-center" style="font-size: 14px;">{{menu.title}}</p>
                                </div>
                            </a>
                        </ng-container>
                        <ng-container *ngIf="menu.fn">
                            <a *ngIf="!menu.disabled; else disabledMenu"
                                placement="right" 
                                container="body" 
                                [ngbTooltip]="menu.title" 
                                class="nk-menu-link" 
                                (click)="menu.fn()"
                                >
                                <div class="d-flex flex-column nk-menu-icon">
                                    <em class="icon ni ni-{{menu.icon}} mb-1"></em>
                                    <p class="fw-light text-center" style="font-size: 14px;">{{menu.title}}</p>
                                </div>
                            </a>
                        </ng-container>
                    </li>                    
                </ul>                
            </div>           
        </div>
    </div>
</div>

<ng-template let-menu="menu" #disabledMenu>
    <a href="javascript:void(0)" class="nk-menu-link" title="{{ menu.title }}" *ngIf="menu.disabled">
        <span class="nk-menu-icon"><em class="icon ni ni-{{menu.icon}}"></em></span>
    </a>
</ng-template>

