import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationResponse } from '@datorama/akita';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiPaginationResponse, ApiResponse } from '../../models/auth/api-response.model';
import { Category } from '../../models/category/category.model';
import { PredictiveSearchParams, PredictiveSearchResponse } from '../../models/market-place/predictive-search.model';
import { PredictiveScoreRequest } from '../../models/market-place/predictve-score.model';
import { SearchOptions } from '../../models/market-place/search-options.model';
import { Product } from '../../models/product/product.model';

@Injectable({
  providedIn: 'root'
})
export class ShopApiService {

  constructor( private _http: HttpClient) { }

  getProducts(searchOptions: SearchOptions): Observable<PaginationResponse<Product>> {
    return this._http.post<ApiResponse<ApiPaginationResponse<Product>>>(`${environment.apiRoot}/${environment.shop}`,
    searchOptions).pipe(
       map((res: ApiResponse<ApiPaginationResponse<Product>>) => {
        return {
          currentPage: searchOptions.page,
          perPage:  searchOptions.take,
          total: res.data.total,
          lastPage: Math.ceil(res.data.total/searchOptions.take),
          pageControls: [ ...Array(Math.ceil(res.data.total/searchOptions.take)).keys() ].map( i => i+1),
          data: res.data.data
        }})
     )
  }

/*   getCategoriesProduct(categories: Array<string>): Observable<PaginationResponse<Product>> {
    return this._http.post<ApiResponse<ApiPaginationResponse<Product>>>(`${environment.apiRoot}/${environment.shopCategories}`, categories).pipe(
      map((res: ApiResponse<ApiPaginationResponse<Product>>) => {
        return {
          currentPage: 1,
          perPage:  20,
          total: res.data.total,
          lastPage: Math.ceil(res.data.total/20),
          pageControls: [ ...Array(Math.ceil(res.data.total/20)).keys() ].map( i => i+1),
          data: res.data.data
        }})
    )
  } */

  getBrands(searchOptions: SearchOptions): Observable<Array<string>> {
    return this._http.post<Array<string>>(`${environment.apiRoot}/${environment.getBrands}`,{ searchOptions })
  }

  getCategoriesProduct(categories: Array<string>): Observable<Array<Category>> {
    return this._http.post<Array<Category>>(`${environment.apiRoot}/${environment.shopCategories}`, categories)
    
  }

  getPredictiveSearch(params: PredictiveSearchParams): Observable<PredictiveSearchResponse> {
    return this._http.post<PredictiveSearchResponse>(`${environment.apiRoot}/${environment.shopSuggestions}`, params);
  }

  updateSearchScore(predictiveScoreRequest: PredictiveScoreRequest) {
    return this._http.post(`${environment.apiRoot}/${environment.shopScoreUpdate}`, predictiveScoreRequest);
  }
}
