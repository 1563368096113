<!-- <app-secondry-header></app-secondry-header> -->
<ng-container *ngIf="headerView$ | async; let view">
    <div class="container-fluid">
        
        <div class="nk-header-wrap">
            <div class="nk-menu-trigger d-xl-none ml-n1">
                <a href="#" class="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu"><em class="icon ni ni-menu"></em></a>
            </div>
            <div class="nk-header-brand" *ngIf="!view.user; else loggedInLogo">
                <a class="logo-link" routerLink="/home">
                    <div class="d-flex align-items-center">
                        <img src="assets/icons/SupplyX-secondry.svg" height="40" width="40">
                        <h4 class="text-primary ml-2">SupplyX</h4>
                    </div>
                </a>
            </div><!-- .nk-header-brand -->
            <div class="nk-header-search ms-3 ms-xl-0 header-search px-2">
                <app-search-bar></app-search-bar>
            </div>
            <div class="nk-header-tools">
                <ul class="nk-quick-nav pr-2">
                    <li *ngIf="view.roleType === userRole.BUYER || view.roleType === userRole.GUEST">
                        <a class="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown">
                            <div class="icon-status icon-status-info"><em class="icon ni ni-heart"></em></div>
                        </a>
                    </li>
                    <li class="dropdown chats-dropdown hide-mb-xs" 
                    *ngIf="view.roleType === userRole.BUYER || view.roleType === userRole.GUEST || view.roleType === userRole.SELLER">
                        <a *ngIf="view.roleType === userRole.BUYER || view.roleType === userRole.SELLER" 
                            href="#" class="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown">
                            <div class="icon-status icon-status-na"><em class="icon ni ni-comments"></em></div>
                        </a>
                        <a *ngIf="view.roleType === userRole.GUEST" [routerLink]="['/sign-in']" class="dropdown-toggle nk-quick-nav-icon">
                            <div class="icon-status icon-status-na"><em class="icon ni ni-comments"></em></div>
                        </a>
                        <div class="dropdown-menu dropdown-menu-xl dropdown-menu-right">
                            <div class="dropdown-head">
                                <span class="sub-title nk-dropdown-title">Recent Chats</span>
                                <a href="javascript:void(0);">Setting</a>
                            </div>
                            <div class="dropdown-body">
                                
                                
                            </div><!-- .nk-dropdown-body -->
                            <div class="dropdown-foot center">
                                <a [routerLink]="['/chat']">View All</a>
                            </div>
                        </div>
                    </li>
                    <li class="dropdown notification-dropdown" *ngIf="view.user">
                        <a href="#" class="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown">
                            <div class="icon-status icon-status-info"><em class="icon ni ni-bell"></em></div>
                        </a>
                        <div class="dropdown-menu dropdown-menu-xl dropdown-menu-right">
                            <div class="dropdown-head">
                                <span class="sub-title nk-dropdown-title">Notifications</span>
                                <a href="javascript:void(0);">Mark All as Read</a>
                            </div>
                            <div class="dropdown-body">
                                <div class="nk-notification">
                                    <div class="nk-notification-item dropdown-inner" *ngFor="let notification of notifications$ | async">
                                        <div class="nk-notification-icon">
                                            <em class="icon icon-circle bg-warning-dim ni ni-curve-down-right"></em>
                                        </div>
                                        <div class="nk-notification-content">
                                            <div class="nk-notification-text">{{ notification.desc }} <span>
                                                <button class="btn btn-white btn-dim btn-primary-outline" (click)="notificationAction(notification)">Join</button>
                                            </span></div>
                                            <div class="nk-notification-time">2 hrs ago</div>
                                        </div>
                                    </div>
                                    
                                </div><!-- .nk-notification -->
                            </div><!-- .nk-dropdown-body -->
                            <div class="dropdown-foot center">
                                <a href="javascript:void(0);">View All</a>
                            </div>
                        </div>
                    </li>
                   <!--  <li class="dropdown list-apps-dropdown">
                        <a href="#" class="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown">
                            <div class="icon-status icon-status-na"><em class="icon ni ni-menu-circled"></em></div>
                        </a>
                        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                            <div class="dropdown-body">
                                <ul class="list-apps">
                                    <li *ngFor="let menuItem of appMenuItems">
                                        <a [routerLink]="menuItem.link">
                                            <span class="list-apps-media"><em class="icon ni {{ menuItem.icon }} {{ menuItem.bg }}"></em></span>
                                            <span class="list-apps-title">{{ menuItem.title }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li> -->
                    <li *ngIf="view.roleType === userRole.BUYER || view.roleType === userRole.GUEST">
                        <a routerLink="/cart" class="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown">
                            <div class="icon-status icon-status-info"><em class="icon ni ni-cart"></em></div>
                        </a>
                    </li>
                    <li class="dropdown user-dropdown" *ngIf="view.user as user">
                        <a href="#" class="dropdown-toggle mr-n1" data-toggle="dropdown">
                            <div class="user-toggle">
                                <div class="user-avatar sm">
                                    <em *ngIf="!user.image?.url" class="icon ni ni-user-alt"></em>
                                    <img *ngIf="user.image?.url" [src] = "user.image?.url" height="35" width="60" alt="">
                                </div>
                            </div>
                        </a>
                        <div class="dropdown-menu dropdown-menu-md dropdown-menu-right">
                            <div class="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                <div class="user-card">
                                    <div class="user-avatar">
                                        <img *ngIf="user.image?.url" [src] = "user.image?.url" height="35" width="60" alt="">
                                    </div>
                                    <div class="user-info">
                                        <span class="lead-text"></span>
                                        <span class="sub-text">{{ user.email }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="dropdown-inner">
                                <ul class="link-list">
                                    <li><a [routerLink]="['/profile']"><em class="icon ni ni-user-alt"></em><span>View Profile</span></a></li>
                                    <!-- <li><a class="dark-switch" href="#"><em class="icon ni ni-moon"></em><span>Dark Mode</span></a></li> -->
                                    <li *ngIf="view.user?.roleType === userRole.ADMIN">
                                      <a href="javascript:void(0);" [routerLink]="['system-config']">
                                        <em class="icon ni ni-setting-alt"></em><span>Account Setting</span>
                                      </a>
                                    </li>
                                    <!-- <li><a href="javascript:void(0);"><em class="icon ni ni-setting-alt"></em><span>Account Setting</span></a></li>
                                    <li><a href="javascript:void(0);"><em class="icon ni ni-activity-alt"></em><span>Login Activity</span></a></li>
                                    <li><a class="dark-switch" href="javascript:void(0);"><em class="icon ni ni-moon"></em><span>Dark Mode</span></a></li> -->
                                </ul>
                            </div>
                            <div class="dropdown-inner">
                                <ul class="link-list">
                                    <li><a (click)="logout()"><em class="icon ni ni-signout"></em><span>Sign out</span></a></li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li class="d-flex align-items-center border-left" *ngIf="!view.user">
                        <div class="d-flex text-center">
                            <em class="icon ni ni-user-alt mr-1 h5"></em>
                        </div>
                        <div>
                            <p class="links" [routerLink]="['/sign-in']">Login</p>    
                        </div>
                        <div><span class="mx-1">/</span></div>
                        <div>
                            <p class="links" [routerLink]="['/register']">Sign Up</p>    
                        </div>
                        <!-- <div data-toggle="dropdown">Hello, sign in <br />
                            <span class="fw-bold">Account & Orders </span>
                        </div> 
                        <a href="#" class="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown">
                            <em class="icon ni ni-user-alt-fill"></em>
                        </a>
                        <div class="dropdown-menu dropdown-menu-xl dropdown-menu-right">
                            <div class="dropdown-body">
                                <div class="text-center p-3">
                                    <a [routerLink]="['/sign-in']" class="btn btn-outline-primary mx-2">Sign In</a>
                                </div>
                                <div class="text-center p-2">
                                    <a [routerLink]="['/register']">Not a user yet? Register here</a>
                                </div>
                            </div>
                        </div> -->
                    </li>
                </ul>
            </div>
        </div>    
    </div>
    <app-category-bar></app-category-bar>
</ng-container>

<ng-template #loggedInLogo>
    <a class="mr-3" routerLink="/home">
        <img src="../../assets/icons/SupplyX-secondry.svg" height="30" width="30" alt="">
    </a>

    <a class="nk-header-app-info" routerLink="/home">
        <span class="sub-text">SupplyX</span>
        <span class="lead-text">Dashboard</span>
    </a>
</ng-template>

