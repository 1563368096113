// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { commentsEndpoints } from "./comment-endpoints";
import { dashboardEndpoints } from "./dashboard-endpoint";
import { endPoints } from "./endpoint";
import { ordersEndpoints } from "./orders-endpoint";
import { projectsEndpoints } from "./project-endpoint";
import { ratingsEndpoints } from "./rating-endPoints";
import { shopEndpoints } from "./shop-endpoint";
import { stripeEndpoints } from "./stripe-endpoints";

export const environment = {
  production: false,
  pexelRoot: 'https://api.pexels.com/videos/search',
  //apiRoot: 'http://localhost:3000',
  //apiRoot: 'http://3.140.47.77:3000',
  //apiRoot: 'https://api.supplyx-dev.com',
  apiRoot: 'https://api.supplyx.io',
  ...endPoints,
  ...shopEndpoints,
  ...projectsEndpoints,
  ...dashboardEndpoints,
  ...commentsEndpoints,
  ...ratingsEndpoints,
  ...stripeEndpoints,
  ...ordersEndpoints,
  //endpoint: 'http://localhost/v1',
  endpoint: 'https://ec2-18-219-143-37.us-east-2.compute.amazonaws.com/v1',
  projectId: 'supplyx',
  databaseId: 'chat',
  chatCollectionId: 'messages',
  topicsCollectionId: 'topics',
  notificationsCollectionId: 'notifications'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
