import { inject, Injectable } from '@angular/core';
import { Models, Query, RealtimeResponseEvent } from 'appwrite';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { take, concatMap, filter, switchMap } from 'rxjs/operators';
import { AppwriteApi, AppwriteEnvironment } from 'src/app/app-write';
import { SessionQuery } from 'src/app/state/session.query';
import { Company } from '../models/company/company.model';

export type Message = Models.Document & {
  user: number;
  message: string;
  to: number;
  topicId: string;
  $createdAt?: number;
};

export type Topic = Models.Document & {
  $createdAt: number;
  $id: string;
  $updatedAt: number;
  from: number;
  fromName: string;
  lastMessage: string;
  to: number;
  toName: string;
}

export type Notification = Models.Document & {
  description: string;
  link: string;
  to: number;
  type: NOTIFICATION_TYPE;
}

export type NOTIFICATION_TYPE = 'MEETING';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private appwriteAPI = inject(AppwriteApi);
  private appwriteEnvironment = inject(AppwriteEnvironment);

  private _messages$ = new BehaviorSubject<Message[]>([]);
  readonly messages$ = this._messages$.asObservable();

  private _chats$ = new BehaviorSubject<Topic[]>([]);
  readonly chats$ = this._chats$.asObservable();

  private _notifications$ = new BehaviorSubject<Array<Notification>>([]);
  readonly notifications$ = this._notifications$.asObservable();

  constructor(private _sessionQuery: SessionQuery) {}

  loadMessages() {
    this.appwriteAPI.database
      .listDocuments<Message>(
        this.appwriteEnvironment.chatCollectionId,
        [],
        100,
        0,
        undefined,
        undefined,
        [],
        ['ASC']
      )
      .then((response) => {
        this._messages$.next(response.documents);
      });
  }

  loadMessagesForTopic(topicId: string) {
    this.appwriteAPI.database
      .listDocuments<Message>(
        this.appwriteEnvironment.chatCollectionId,
        [
          Query.equal('topicId', topicId)
        ],
        100,
        0,
        undefined,
        undefined,
        [],
        ['ASC']
      )
      .then((response) => {
        this._messages$.next(response.documents);
      });
  }

  sendMessage(message: string) {
    return this._sessionQuery.user$.pipe(
      filter((user) => !!user),
      take(1),
      concatMap((user) => {
        const data = {
          user: user!.id,
          message,
          to: user!.id
        };

        return this.appwriteAPI.database.createDocument(this.appwriteEnvironment.chatCollectionId,
          'unique()',
          data,
          ['role:all'],
          [`role:all`]
        );
      })
    );
  }

  sendMessageForUser(message: Message) {
    return from(this.appwriteAPI.database.createDocument(this.appwriteEnvironment.chatCollectionId,
      'unique()',
      message,
      ['role:all'],
      [`role:all`]
    ));
  }

  listenToMessages() {
    return this.appwriteAPI.database.client.subscribe(
      `databases.${this.appwriteEnvironment.databaseId}.collections.${this.appwriteEnvironment.chatCollectionId}.documents`,
      (res: RealtimeResponseEvent<Message>) => {
        if (res.events.includes(`databases.${this.appwriteEnvironment.databaseId}.collections.messages.documents.*.create`)) {
          const messages: Message[] = [...this._messages$.value, res.payload];
          this._messages$.next(messages);
        }
      }
    );
  }

  listenToChats() {
    return this.appwriteAPI.database.client.subscribe(
      `databases.chat.collections.topics.documents`,
      (res: RealtimeResponseEvent<Topic>) => {
        if (res.events.includes(`databases.${this.appwriteEnvironment.databaseId}.collections.topics.documents.*.create`)) {
          const topics: Topic[] = [...this._chats$.value, res.payload];
          this._chats$.next(topics);
        }
      }
    );
  }

  loadChats() {
    this.appwriteAPI.database
      .listDocuments<Topic>(
        this.appwriteEnvironment.topicsCollectionId,
        [],
        100,
        0,
        undefined,
        undefined,
        [],
        ['ASC']
      )
      .then((response) => {
        this._chats$.next(response.documents);
      });
  }

  loadChatsForUser(userId: number) {
    this.loadTopics('from', userId).pipe(
      switchMap( response => {
        if(response.total === 0) {
          return this.loadTopics('to', userId)
        }
       return of(response) 
      })
    ).subscribe( response => this._chats$.next(response.documents));
  }

  loadTopics(attribute: string, userId: number ) {
    return from(this.appwriteAPI.database.listDocuments<Topic>(
      this.appwriteEnvironment.topicsCollectionId, [ Query.equal(attribute, userId)],
      100, 0, undefined, undefined, [], ['ASC']
    ));
  }

  createChat(company: Company) {
    return this._sessionQuery.user$.pipe(
      filter((user) => !!user),
      take(1),
      concatMap((user) => {
        const data = {
          from: user!.id,
          fromName: `${user!.firstName} ${user!.lastName}`,
          to: company.user.id,
          toName: `${company.user.firstName} ${company.user.lastName}`,
          lastMessage: null
        };

        return this.appwriteAPI.database.createDocument(this.appwriteEnvironment.topicsCollectionId,
          'unique()',
          data,
          ['role:all'],
          [`role:all`]
        );
      })
    );
  }

  checkChat(fromUser: number, toUser: number) {
    return from(this.appwriteAPI.database.listDocuments<Topic>(
      this.appwriteEnvironment.topicsCollectionId, [ 
        Query.equal('from', fromUser),
        Query.equal('to', toUser)
      ],
      100, 0, undefined, undefined, [], ['ASC']
    ));
  }

  checkForExistingChatsForUser(toUser: number): Observable<Models.DocumentList<Topic>> {
    return this._sessionQuery.user$.pipe(
      switchMap( user => this.checkChat(user!.id, toUser).pipe(
        switchMap( response => {
          if(response.total === 0) {
            return this.checkChat(toUser, user!.id);
          }
          return of(response)
        })
      ))
    );
  }

  listenToNotifications(userId: number) {
    return this.appwriteAPI.database.client.subscribe(
      `databases.${this.appwriteEnvironment.databaseId}.collections.${this.appwriteEnvironment.notificationsCollectionId}.documents`,
      (res: RealtimeResponseEvent<Notification>) => {
        if (res.events.includes(`databases.${this.appwriteEnvironment.databaseId}.collections.notifications.documents.*.create`) && res.payload.to === userId) {
          const notifications: Notification[] = [...this._notifications$.value, res.payload];
          this._notifications$.next(notifications);
        }
      }
    );
  }

  loadNotifications(userId: number) {
    this.appwriteAPI.database
      .listDocuments<Notification>(
        this.appwriteEnvironment.notificationsCollectionId,
        [ Query.equal('to', userId)],
        100,
        0,
        undefined,
        undefined,
        [],
        ['ASC']
      )
      .then((response) => {
        this._notifications$.next(response.documents);
      });
  }

  createNotification(desc: string, to: number, link: string, type: string) {
    const data = {
      desc,
      to,
      link,
      type
    };

    return this.appwriteAPI.database.createDocument(this.appwriteEnvironment.notificationsCollectionId,
      'unique()',
      data,
      ['role:all'],
      [`role:all`]
    );
  }
}