import { Component, OnInit, TemplateRef } from '@angular/core';
import { FlashService } from 'src/app/shared/services/flash/flash.service';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  host: {'[class.ngb-toasts]': 'true'}
})
export class ToasterComponent implements OnInit {

  constructor(public flashService: FlashService) { }

  ngOnInit(): void {
  }

  isTemplate(toast: string | TemplateRef<any>) { return toast instanceof TemplateRef; }

}
