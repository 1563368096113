import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../../models/auth/api-response.model';
import { ReferenceData } from '../../models/reference-data/reference-data.model';

@Injectable({
  providedIn: 'root'
})
export class ReferenceDataService {

  constructor( private _http: HttpClient) { }

  getReferenceData(): Observable<ApiResponse<ReferenceData>> {
    return this._http.get<ApiResponse<ReferenceData>>(`${environment.apiRoot}/${environment.referenceData}`);
  }
}
