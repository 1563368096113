import { Component, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PaginationResponse } from '@datorama/akita';
import { BehaviorSubject, combineLatest, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap, takeUntil, tap, filter, distinctUntilChanged } from 'rxjs/operators';
import { Category } from 'src/app/shared/models/category/category.model';
import { Hit } from 'src/app/shared/models/market-place/predictive-search.model';
import { SearchOptions } from 'src/app/shared/models/market-place/search-options.model';
import { Product } from 'src/app/shared/models/product/product.model';
import { ReferenceData } from 'src/app/shared/models/reference-data/reference-data.model';
import { ShopApiService } from 'src/app/shared/services/shop/shop.service';
import { AppStateService } from 'src/app/state/home/app-state.service';

export interface ISearchBarView {
  searchOptions: SearchOptions,
  referenceData: ReferenceData | null,
  pagination: PaginationResponse<Product>
}

export interface IPredictiveSearch {
  hit: Hit;
  searchTerm: string;
}

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnDestroy{

  unSubscribe$: Subject<void> = new Subject();
  searchCtrl = new FormControl('');
  perPage: number = 50;
  referenceData$: Observable<ReferenceData | null> = this._appStateService.getReferenceData();
  searchOptions$: Observable<SearchOptions> = this._appStateService.searchOption$;
  productList$: BehaviorSubject<Array<Product>> = new BehaviorSubject<Array<Product>>([]);
  searchResults$: Observable<PaginationResponse<Product>> = this.getAutocompleteData();
  searchBarView$: Observable<ISearchBarView> = this.getSearchBarView();
  predictiveSearch$: Observable<Array<IPredictiveSearch>> = this.getPredictiveSearch();
  emptyPagination: PaginationResponse<Product> = {
    currentPage: 1, 
    perPage: this.perPage, 
    lastPage: 1,
    data: []
  }
  keyword: string = '';
  
  constructor(private _appStateService: AppStateService,
    private _shopApiService: ShopApiService) { 
      this._appStateService.updateScore$.pipe(
        switchMap( hit => hit ? this._shopApiService.updateSearchScore({
          type: 'add',
          id: hit.id,
          fields: {
            popularity: +hit.fields.popularity[0] + 1,
            term: hit.fields.term[0]
          }
        }): of(null)),
        takeUntil(this.unSubscribe$)
      ).subscribe();
    }

  getSearchBarView(): Observable<ISearchBarView> {
    return combineLatest([this.referenceData$, this.searchOptions$, this.searchResults$]).pipe(
      map(([referenceData, searchOptions, pagination]) => ({
        referenceData,
        searchOptions,
        pagination
      }))
    );
  }

  selectCategory(category: Category, searchOptions: SearchOptions) {
    this._appStateService.selectCategory(category, searchOptions);
  }

  getAutocompleteData(): Observable<PaginationResponse<Product>> {
    return this.searchCtrl.valueChanges.pipe(
      debounceTime(500),
      startWith(''),
      switchMap( searchTerm => searchTerm ? this._shopApiService.getProducts({
        page: 1,
        take: this.perPage,
        productName: searchTerm
      }).pipe(
        catchError((err) => {
        throwError(err);
        return of(this.emptyPagination)
      })) : of(this.emptyPagination)),
      tap( pagination => {
        this.productList$.next(pagination.data)
      })
    )
  }

  getPredictiveSearch(): Observable<Array<IPredictiveSearch>> {
    return this.searchCtrl.valueChanges.pipe(
      filter( () => this.searchCtrl.valid),
      debounceTime(300),
      startWith(''),
      switchMap( searchTerm => searchTerm ? this._shopApiService.getPredictiveSearch({
        query: `${searchTerm}*`,
        //queryParser: 'structured',
      }).pipe(
        map( response => response.hits.hit.map( hit => ({
          searchTerm: hit.fields.term[0],
          hit: hit
        })))
      ) : of([]))
    )
  }

  selectTerm( predictiveSearch: IPredictiveSearch, searchOptions: SearchOptions) {
    this._appStateService.searchProduct(predictiveSearch.searchTerm, searchOptions);
    this._appStateService.updateScore$.next(predictiveSearch.hit);
  }

  search(searchOptions: SearchOptions) {
    if(this.searchCtrl.value) {
      this._appStateService.searchProduct(this.searchCtrl.value, searchOptions);
    }
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

}
