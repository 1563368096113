import { inject, Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { NotificationConfig } from 'src/app/notificationEnv';

export enum EVENT_TYPE {
  OK = 'OK',
  PUSH_NOTI ='PUSH_NOTI',
  NOTIFICATION = 'NOTIFICATION'
};

export interface NotificationEvent{
  type: EVENT_TYPE;
  data: any;
}

export interface PushNotification{
  fromUser: number;
  toUser: number;
  message: string;
  link: string;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notificationConfig = inject(NotificationConfig);
  ws: WebSocketSubject<any> | null = null;

  connect(userId: number) {
    this.ws = webSocket(`${this.notificationConfig.wsEndPoint}/${userId}`);
    console.log(`Websocket listening at: ${this.notificationConfig.wsEndPoint}/${userId}`);
    this.ws.subscribe(
      msg => this.handleMessage(msg), // Called whenever there is a message from the server.
      err => this.handleError(err), // Called if at any point WebSocket API signals some kind of error.
      () => console.log('complete') // Called when connection is closed (for whatever reason).
    );
  }

  sendMessage() {
    this.ws?.next({ type: EVENT_TYPE.PUSH_NOTI, data: {
      fromUser: 2,
      toUser: 3,
      message: "This is so awesome",
      link: ''
    } })
  }

  handleMessage(notificationEvent: NotificationEvent) {
    switch (notificationEvent.type) {
      case EVENT_TYPE.PUSH_NOTI:
        console.log(`Websocket: ${notificationEvent.type}, ${notificationEvent.data}`)
        break;
      case EVENT_TYPE.NOTIFICATION:
        
        break;
      default:
        console.log(`Websocket: ${notificationEvent.type}, ${notificationEvent.data}`)
        break;
    }
  }

  handleError(err: any) {
    console.log(err)
  }
}
