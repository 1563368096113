import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxSpinnerModule } from "ngx-spinner";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FullContentLayoutComponent } from './layout/full-content-layout/full-content-layout.component';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { PrimarySidebarComponent } from './sidebar/primary-sidebar/primary-sidebar.component';
import { environment } from 'src/environments/environment';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { HttpRequestInterceptor } from './shared/middleware/http-request.interceptor';
import { NgbModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ToasterModule } from './lib/toaster/toaster.module';
import { SecondarySidebarModule } from './sidebar/secondary-sidebar/secondary-sidebar.module';
import { HeaderModule } from './header/header.module';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { ToastrModule } from 'ngx-toastr';
import { FooterModule } from './footer/footer.module';

const akitaDevTools = environment.production ? AkitaNgDevtools.forRoot() : AkitaNgDevtools.forRoot();

@NgModule({
  declarations: [
    AppComponent,
    FullContentLayoutComponent,
    ContentLayoutComponent,
    PrimarySidebarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    AkitaNgRouterStoreModule,
    akitaDevTools,
    NgbModule,
    AutocompleteLibModule,
    ToasterModule,
    SecondarySidebarModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    HeaderModule,
    FooterModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
