import { ChangePassword, User } from './../../models/auth/user.model';
import { UserRegister } from './../../models/auth/user-register.modal';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserLogin } from '../../models/auth/user-login.model';
import { ApiResponse } from '../../models/auth/api-response.model';
import { SessionQuery } from 'src/app/state/session.query';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private _http: HttpClient, private _sessionQuery: SessionQuery, ) { }

  login(user: UserLogin): Observable<ApiResponse<User>> {
    return this._http.post<ApiResponse<User>>(`${environment.apiRoot}/${environment.login}`, user)
  }

  signup(user: UserRegister): Observable<UserRegister> {
    return this._http.post<UserRegister>(`${environment.apiRoot}/${environment.signup}`, user)
  }
  
  updateUser(user: User): Observable<ApiResponse<User>> {
    let t = localStorage.getItem("token");
    return this._http.post<ApiResponse<User>>(`${environment.apiRoot}/${environment.updateUser}`,
    user, { headers: { "Authorization": "Bearer " + t} })
  }

  changePassword(changePassword: ChangePassword) {
    let t = localStorage.getItem("token");
    return this._http.post<ApiResponse<User>>(`${environment.apiRoot}/${environment.changePassword}`,
    changePassword, { headers: { "Authorization": "Bearer " + t} });
  }

  logout() {
    localStorage.clear();
    this._sessionQuery.updateUserDetails(null);
  }
}
