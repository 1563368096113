import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Store, StoreConfig } from "@datorama/akita";
import { UserRole } from "../enums/user-role";
import { User } from "../shared/models/auth/user.model";
import { Catalog } from "../shared/models/catalog/catalog.model";

export interface SessionState {
    isLoggedIn: boolean,
    user: User | null,
    role: UserRole,
    token: string | null,
    showSecondarySideBar: boolean,
    editCatalog: Catalog
}

export const initialSessionState = (): SessionState => ({
    isLoggedIn: false,
    user: null,
    role: UserRole.GUEST,
    token: null,
    editCatalog: {} as Catalog,
    showSecondarySideBar: false
})

@Injectable({
    providedIn: 'root'
})
@StoreConfig({ name: 'session-store' })
export class SessionStore extends Store<SessionState> {
    constructor(private _jwtHelper: JwtHelperService) {
        super(initialSessionState());
        this.loadFromStorage();
    }

    loadFromStorage() {
        const token = localStorage.getItem('token');
        const rawUser = localStorage.getItem('user');
        if(token && rawUser) {
            const tokenInStorage = this._jwtHelper.decodeToken(token);
            const userInfo: User = JSON.parse(rawUser);
            this.update({
                isLoggedIn: true,
                user: userInfo,
                role: userInfo.roleType,
                token: tokenInStorage
            })
        }
    }
}