import { Routes } from '@angular/router';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const CONTENT_ROUTES: Routes = [
    {
        path:'sign-in' , loadChildren: () => import('../feature/auth/sign-in/sign-in.module').then(m => m.SignInModule)
    },
    {
        path:'register' , loadChildren: () => import('../feature/auth/register/register.module').then(m => m.RegisterModule)
    }    
];  
